<template>
  <div>
    <div class="row">
      <div class="col-md-2 col-sm-4">
        <div class="text-center">
          <label>Contents</label>
        </div>
        <ul class="nav flex-column nav-pills">
          <template v-for="page in contents">
            <li
              v-if="page.dropdown"
              :key="page.title"
              class="nav-item dropdown"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
              >
                {{ page.title }}
                <span class="caret" />
              </a>
              <div class="dropdown-menu">
                <a
                  v-for="tab in page.tabs"
                  :key="tab.title"
                  data-toggle="pill"
                  class="dropdown-item"
                  href="#"
                  @click="goPage(tab.pg)"
                >
                  {{ tab.title }}
                </a>
              </div>
            </li>
            <li
              v-else
              :key="page.title"
              class="nav-item"
            >
              <a
                data-toggle="pill"
                class="nav-link"
                href="#"
                @click="goPage(page.pg)"
              >
                {{ page.title }}
              </a>
            </li>
          </template>

          <li class="text-center">
            <label>Select page number</label>
            <div class="row">
              <div class="col">
                <button @click="prevPage">
                  <ChevronLeftIcon title="" />
                </button>
                <input
                  v-model.number="inputNumber"
                  :max="totalPages"
                  min="0"
                  size="3"
                  class="text-center"
                >
                <button @click="nextPage">
                  <ChevronRightIcon title="" />
                </button>
              </div>
            </div>
          </li>
          <li class="text-center">
            <a
              class="nav-link"
              href="/static/MotionUserGuide.pdf"
              download
            >Download PDF</a>
          </li>
        </ul>
      </div>

      <div class="col-md-10 col-sm-8">
        <pdf
          ref="pdf"
          :page="pageNumber"
          src="/static/MotionUserGuide.pdf"
          style="border: 1px solid red"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * View to allow reading of the Motion Manual.
 */
import pdf from 'vue-pdf'
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue'
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    pdf: pdf,
  },
  data () {
    return {
      inputNumber: 2,
      totalPages: 29,
      contents: [
        {
          title: 'Introduction/Login',
          dropdown: false,
          pg: 4,
        },
        {
          title: 'Types of Users',
          dropdown: false,
          pg: 5,
        },
        {
          title: 'Motion Menus',
          dropdown: true,
          tabs: [
            {
              title: 'Overview',
              pg: 5,
            },
            {
              title: 'Projects',
              pg: 6,
            },
          ],
        },
        {
          title: 'Project Overview',
          dropdown: true,
          tabs: [
            {
              title: 'Project Flow',
              pg: 8,
            },
            {
              title: 'Project View',
              pg: 9,
            },
            {
              title: 'Initiate Request',
              pg: 13,
            },
            {
              title: 'Review, Assessment and Approval',
              pg: 15,
            },
            {
              title: 'Provisioning',
              pg: 20,
            },
            {
              title: 'Project Roles and States',
              pg: 21,
            },
          ],
        },
        {
          title: 'Review Bookings',
          dropdown: true,
          tabs: [
            {
              title: 'Design Review',
              pg: 25,
            },
            {
              title: 'CR Review',
              pg: 28,
            },
          ],
        },
      ],
    }
  },
  computed: {
    /**
     * @description adds 1 to inputNumber as pdf page number doesnt align with contents page
     * @returns Int
    */
    pageNumber () {
      if (this.inputNumber % 1 === 0) {
        return this.inputNumber + 1
      } else {
        return 1
      }
    },
  },
  methods: {
    /**
     * @description sets inputNumber
     * @param Int
    */
    goPage (pageNum) {
      this.inputNumber = pageNum
    },
    /**
     * @description decrements inputNumber
    */
    prevPage () {
      if (this.inputNumber > 0) {
        this.inputNumber -= 1
      }
    },
    /**
     * @description increments inputNumber
    */
    nextPage () {
      if (this.inputNumber < this.totalPages) {
        this.inputNumber += 1
      }
    },
  },
}
</script>
